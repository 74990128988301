.App {
  text-align: center;
}

body {
  font-family: 'Raleway', sans-serif;
  height: 100%;
  background: linear-gradient(white 0%, white 95%, black 100%);
  background-attachment: fixed;
  z-index: 1;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.whatsapp-float img {
  width: 70px;
  height: 70px;
  border-radius: 40%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  padding: 10%;
  opacity: 0.7;
  overflow: visible;

}

.whatsapp-float img:hover {
  transform: scale(1.1);
  lighting-color: aqua;
  opacity: 1;

}

.titleHeader {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.nav {
  font-size: larger;
  color: white
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.animatedItem {
  animation: slideIn 1s ease-out;
}

.footer-item :hover {
  font-weight: 700;
}

.bi {
  max-width: 200px;
  max-height: 200px;
}

.quality-icon {
  max-height: 150px;
  margin: -20px;
}

Button :hover {
  transform: scale(.9);
  transition: all 0.3s ease;

}

Button :hover>* {
  transform: scale(1);
}

.circle {
  position: relative;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  border: 2px solid black;
}

.circle:hover {
  border: 1px solid black;
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.circle .Tooltip .example-arrow{
visibility: hidden;}

.img-magnifier-container {
  position: relative;
  width: 10dvw;
  height: auto;
}

.magnifier-img{
 width: 10dvw;
 height: auto;
  
}

.magnifier-image{
  width: 5dvw;
  height: 5vw;
  border: 2px solid #fff;
  border-radius: 20%;
}

.button {
  border: none;
  color: #fff;
  background-image: linear-gradient(135deg, #f23737 0%, #c01313 31%, #5f0000 100%);
  border-radius: 20px;
  font-family: inherit;
  font-size: 17px;
  padding: 0.6em 1.5em;
  transition: scale 0.2s ease;
}

.button:hover {
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse512 1.5s infinite;
}

.button:active {
  scale: 0.95;
}

@keyframes pulse512 {
  0% {
    box-shadow: 0 0 0 0 #98040466;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(178, 21, 24, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(250, 126, 128, 0);
  }
}

